

















import { Component, Vue } from 'vue-property-decorator'

import sectionElement from '@/components/page/page-section.vue'
import container from '@/components/page/page-container.vue'

@Component({
  components: {
    sectionElement,
    container,
  },
})
export default class extends Vue {
  //
}
