
























































































import { Component, Mixins } from 'vue-property-decorator'

import {
  //
  inputElement,
  selectElement,
  checkboxElement,
  modalElement,
} from '@/elements'

import sectionElement from '@/components/page/page-section.vue'
import container from '@/components/page/page-container.vue'
import formElement from '@/components/elements/form-element.vue'

import validationMixin from '@/mixins/validation.mixin'

import Form from '@/types/form.d'
import { AxiosError } from 'axios'

@Component({
  components: {
    sectionElement,
    container,
    formElement,
    inputElement,
    selectElement,
    checkboxElement,
    modalElement,
  },
})
export default class extends Mixins(validationMixin) {
  form: Form = {
    first_name: {
      value: null,
      error: null,
      validate: (value) => this.require(value),
    },
    last_name: {
      value: null,
      error: null,
      validate: (value) => this.require(value),
    },
    date_of_birth: {
      value: null,
      error: null,
      get: (value) =>
        value
          .split('-')
          .reverse()
          .join('-'),
    },
    email: {
      value: null,
      error: null,
      validate: (value) => {
        if (!value) {
          return this.requiredError
        }

        if (this._validateEmail(value)) {
          return (this.$i18n.messages[this.$i18n.locale].errors as Record<string, string>)
            .invalid_email as string
        }

        return null
      },
    },
    education: {
      value: null,
      error: null,
      validate: (value) => this.require(value),
    },
    sports: {
      value: null,
      error: null,
    },
    expectations: {
      value: null,
      error: null,
    },
    privacy_policy: {
      value: false,
      error: null,
      validate: (value) => this.require(value),
    },
    receive_updates: {
      value: false,
      error: null,
    },
  }

  isLoading = false

  success = false

  get requiredError(): string {
    return (this.$i18n.messages[this.$i18n.locale].errors as Record<string, string>).required
  }

  get studies(): string[] {
    return this.$i18n.messages[this.$i18n.locale].studies as string[]
  }

  require(value: unknown | null): string | null {
    if (!value) {
      return this.requiredError
    }
    return null
  }

  resetForm(): void {
    Object.keys(this.form).forEach((formProperty) => {
      this.form[formProperty].value = null

      this.$nextTick(() => {
        this.form[formProperty].error = null
      })
    })
  }

  submit(formValues: () => Record<string, unknown>, done: (errors?: AxiosError) => void): void {
    this.isLoading = true

    this.$axios
      .post(process.env.VUE_APP_FEEDBACK_URL, formValues())
      .then(() => {
        this.success = true
        this.resetForm()
        done()
      })
      .catch((errors) => {
        done(errors)
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
