








import { Component, Vue } from 'vue-property-decorator'

import ourStory from '@/components/about/our-story-section.vue'
import solution from '@/components/about/solution-section.vue'
import aboutForm from '@/components/about/about-form.vue'

@Component({
  components: {
    ourStory,
    solution,
    aboutForm,
  },
  metaInfo() {
    const about = this.$t('header.about')

    return {
      title: `Sweevy | ${about}`,
      meta: [
        {
          name: 'description',
          content:
            'Sweevy is een community, dóór bewegingsdeskundigen, vóór bewegingsdeskundigen. Vanuit onze er varing als kinesitherapeut en bewegingsdeskundige leerden we dat onze stiel dringend nood had aan digitalisering',
        },
      ],
    }
  },
})
export default class extends Vue {}
